/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { GatsbyImage } from 'gatsby-plugin-image';
import SEO from '../components/organisms/seo';
import { secondsToMinutes } from '../utils/helper.js';
import Cursor from '../components/atoms/cursor.js';
import RichtText from '../components/atoms/richtext';
import { useWindowSize } from '../hooks/useWindowSize';

const Project = ({ data, pageContext }) => {
  const { projects } = data;
  const { next } = pageContext;

  const size = useWindowSize();

  const [playVideo, setPlayVideo] = useState(true);
  const [muteVid, setMuteVid] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [vidLoaded, setVidLoaded] = useState(false);
  const [vidHover, setVidHover] = useState(true);

  const [videoDuration, setVideoDuration] = useState('0:00');
  const [videoCurrentTime, setVideoCurrentTime] = useState('');

  const [progressWidth, setProgressWidth] = useState();
  const [progressScrub, setProgressScrub] = useState();

  const videoRef = useRef();

  useEffect(() => {
    setIsMobile(size.width < 768);
  }, [size]);

  useLayoutEffect(() => {
    if (vidLoaded) {
      videoRef.current.load();
      videoRef.current.play();
    }
  }, [vidLoaded]);

  useLayoutEffect(() => {
    if (vidLoaded) {
      videoRef.current[playVideo ? 'play' : 'pause']();
      videoRef.current.muted = muteVid;
    }
  }, [vidLoaded, playVideo, muteVid]);

  const handleProgress = () => {
    setProgressWidth((videoCurrentTime / videoDuration) * 100);
  };

  const scrub = (e) => {
    const scrubTime =
      (e.nativeEvent.offsetX / e.target.offsetWidth) * videoDuration;
    videoRef.current.currentTime = scrubTime;
    setVideoCurrentTime(scrubTime);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!vidLoaded) {
        window.location.reload();
      }
    }, 8000);

    return () => clearTimeout(timer);
  }, [vidLoaded]);

  return (
    <>
      <SEO siteTitle={projects.title} />
      <StyledProject style={{ height: size.height }}>
        <StyledVideoPlayer style={{ height: size.height }}>
          <video
            className="video"
            ref={videoRef}
            onClick={() => setPlayVideo(!playVideo)}
            onTimeUpdate={() => {
              setVideoCurrentTime(videoRef.current.currentTime);
              handleProgress();
            }}
            onLoadedMetadata={() => {
              setVideoDuration(videoRef.current.duration);
              setVidLoaded(true);
            }}
            autoPlay
            loop
            muted
            playsInline
            onMouseOver={() => setVidHover(true)}
            onMouseLeave={() => setVidHover(false)}
          >
            <source src={projects.video} type="video/mp4" />
          </video>
          {!vidLoaded && (
            <figure className="videoplayer-preview-img">
              <GatsbyImage
                image={projects.previewImage.asset.gatsbyImageData}
                alt="Videoplayer Preview Image"
              />
            </figure>
          )}
          {!isMobile && vidHover && (
            <Cursor
              // eslint-disable-next-line no-nested-ternary
              text={vidLoaded ? (!playVideo ? 'Play' : 'Pause') : 'Loading'}
            />
          )}
          {isMobile && !vidLoaded && (
            <div className="loader" style={{ height: size.height }}>
              <span>Loading</span>
            </div>
          )}
          <div className="controls">
            <div className="desc">
              <p>{projects.title}</p>
              <p>{projects.mouseover}</p>
              <div>
                <RichtText blocks={projects._rawDetails} />
              </div>
            </div>
            <motion.div
              className="wrapper"
              onClick={scrub}
              onMouseDown={() => setProgressScrub(true)}
              onMouseUp={() => setProgressScrub(false)}
              onMouseMove={(e) => progressScrub && scrub(e)}
              initial={{ opacity: 0, pointerEvents: 'none' }}
              animate={{
                opacity: vidLoaded ? 1 : 0,
                pointerEvents: vidLoaded ? 'all' : 'none',
              }}
            >
              <motion.div className="progress">
                <div className="filled" style={{ width: `${progressWidth}%` }}>
                  <span />
                </div>
              </motion.div>
            </motion.div>
            <motion.div
              className="time"
              initial={{ opacity: 0, pointerEvents: 'none' }}
              animate={{
                opacity: vidLoaded ? 1 : 0,
                pointerEvents: vidLoaded ? 'all' : 'none',
              }}
            >
              <div>
                {` ${
                  videoCurrentTime !== '0:00'
                    ? secondsToMinutes(videoCurrentTime)
                    : videoCurrentTime
                } |
          ${
            secondsToMinutes(videoDuration)
              ? secondsToMinutes(videoDuration)
              : '0:00'
          }`}
              </div>
              <button type="button" onClick={() => setMuteVid(!muteVid)}>
                {muteVid ? 'UNMUTE' : 'MUTE'}
              </button>
            </motion.div>
          </div>
        </StyledVideoPlayer>
        <div className="btns">
          <Link to="/">BACK</Link>
          <Link to={`/${next}`}>NEXT</Link>
        </div>
      </StyledProject>
    </>
  );
};

const StyledProject = styled.section`
  overflow: hidden;
  position: relative;

  .btns {
    padding: var(--sp-1-3);
    position: absolute;
    bottom: var(--sp-0);
    left: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

const StyledVideoPlayer = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  position: relative;

  .loader {
    position: absolute;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    text-align: center;
    left: 0;
  }

  .videoplayer-preview-img {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;

    .gatsby-image-wrapper {
      width: 100vw;
      height: 100vh;
    }
  }

  video {
    cursor: none;
  }

  .video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .controls {
    position: absolute;
    bottom: var(--sp-8);
    width: 100%;
    color: white;
    /* padding-bottom: var(--sp-8); */

    .desc {
      padding-left: var(--sp-1-3);
      padding-bottom: var(--sp-1);
    }

    .time,
    .wrapper {
      width: calc(100% - var(--sp-1-3) * 2);
      margin: 0 auto;
    }

    .time {
      font-size: var(--fs-small);
      display: flex;
      justify-content: space-between;
    }

    .wrapper {
      height: 15px;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:hover {
        span {
          transform: scale(1);
        }
      }
    }

    .progress {
      width: 100%;
      position: relative;
      display: flex;
      height: 1px;
    }

    .filled {
      background: white;
      transition: width 0.1s;
      position: relative;

      span {
        position: absolute;
        right: -8px;
        top: -4px;
        background: white;
        border-radius: 50%;
        height: 10px;
        width: 10px;
        transform: scale(0);
        transition: transform 0.2s ease-out;
      }
    }
  }
`;

export const query = graphql`
  query($slug: String!) {
    projects: sanityProject(slug: { current: { eq: $slug } }) {
      title
      video
      previewImage {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      slug {
        current
      }
      mouseover
      _rawDetails
    }
  }
`;

Project.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
};

export default Project;
