import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useEventListener } from '../../hooks/useEventListener';
import { useClientSide } from '../../hooks/useClientSide';

const Cursor = ({ text, initial, animate, subtitle }) => {
  const cursorRef = useRef(null);
  const isClient = useClientSide();

  const handleCursor = (e) => {
    if (cursorRef.current) {
      cursorRef.current.style.left = `${e.pageX}px`;
      cursorRef.current.style.top = `${e.pageY - 10}px`;
    }
  };
  useEventListener('mousemove', handleCursor, isClient && window);

  const [hover, setHover] = useState(false);
  const buttons = isClient && document.querySelectorAll('button');
  const links = isClient && document.querySelectorAll('a');
  useEffect(() => {
    buttons.forEach((button) => {
      button.addEventListener('mouseover', () => setHover(true));
      button.addEventListener('mouseleave', () => setHover(false));
    });
    links.forEach((link) => {
      link.addEventListener('mouseover', () => setHover(true));
      link.addEventListener('mouseleave', () => setHover(false));
    });

    return () => {
      buttons.forEach((button) => {
        button.removeEventListener('mouseover', () => setHover(true));
        button.removeEventListener('mouseleave', () => setHover(false));
      });
      links.forEach((link) => {
        link.removeEventListener('mouseover', () => setHover(true));
        link.removeEventListener('mouseleave', () => setHover(false));
      });
    };
  });

  return (
    <StyledCursor
      ref={cursorRef}
      className={hover ? 'cursor-hover' : undefined}
      initial={initial}
      animate={animate}
    >
      {text} <br />
      {subtitle}
    </StyledCursor>
  );
};

const StyledCursor = styled(motion.span)`
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 999;
  pointer-events: none;
  /* transition: 0.15s ease-out; */
  white-space: nowrap;
  text-align: center;
`;

export default Cursor;
